import './App.css';

function App() {
  return (
  <div>
    <div className='flex  font-custom background-img bg-opacity-95 min-h-screen text-white'>
    <div className='flex flex-col grow justify-center items-center px-6 md:px-0'>
    <div className='md:mt-28 mt-16 min-h-screen'>
    <img className='h-14 px-2 mx-auto bg-white rounded-md mb-4' src="/png/nirmaan_logo.png"></img>
    <div className='font-semibold md:text-xl text-lg mb-4 text-center'>Jul 27-28, 2023(09:00 - 18:00 / GMT +05:30)| Hyderabad, Telangana</div>
    <div className='font-bold md:text-6xl text-3xl mb-4 text-center'><div>NIRMAAN SOCIAL IMPACT</div><div className='text-center'>CONCLAVE 2023</div></div>
    <div className='font-semibold text-xl text-center'>Achieving An Economically Empowered And Knowledge Driven Society</div>
    <div className='grid grid-cols-2 md:grid-cols-5 gap-6 md:mt-20 mt-10'>
      <a href='#Event_details' className='flex py-3 px-4 bg-white hover:bg-gray-500 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-5' src="/png/home.png"></img><span className='my-auto'>Event Details</span></a>
      <a href='#Agenda' className='flex py-3 px-4 bg-white hover:bg-gray-500 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-5' src="/png/calendar.png"></img><span className='my-auto'>Agenda</span></a>
      <a href='#Speakers' className='flex py-3 px-4 bg-white hover:bg-gray-500 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/mic.png"></img><span className='my-auto'>Speakers</span></a>
      <a href='#Partners' className='flex py-3 px-4 bg-white hover:bg-gray-500 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/partners.png"></img><span className='my-auto'>Partners</span></a>
      <a href='#Venue' className='flex py-3 px-4 bg-white hover:bg-gray-500 cursor-pointer text-black rounded-lg'><img className='my-auto pr-2 h-6' src="/png/venue.png"></img><span className='my-auto'>Venue</span></a>
    </div>
    </div>

    {/* Event Details */}
    <div className='md:mx-32 rounded-[2rem] my-28 bg-white text-black ' id="Event_details">
      <div className='flex font-bold mt-16 md:text-5xl text-4xl  justify-center  '>Event Details</div>
      <div className='md:mx-16 mx-6 md:mt-20 mt-10 text-xl mb-4'>Welcome to the Nirmaan Social Impact Conclave - Empowering Change, Inspiring Leadership</div>
      <div className='md:mx-16 mx-6 mb-4 text-xl indent-10  hyphens-auto text-justify'>At Nirmaan, we believe that true social impact is not just about inspiring others, but also fostering an environment that empowers individuals to create change. Our two-day conclave is thoughtfully designed to inspire leadership among our esteemed donors and partners, creating an environment of collaboration and innovation. With four insightful sessions each day, we aim to address critical aspects of societal progress, focusing on holistic education, career guidance, health and mental well-being, food initiatives, orphanages and shelter homes, women empowerment, diversity and inclusion, environmental sustainability, rural development, and disaster response.</div>
      <ul className='list-disc md:mx-28 mx-12 space-y-4 text-xl hyphens-auto text-justify'>
      <li><span className='font-semibold'>Day 1 - Empowering Lives: </span>Experience a day focused on creating a positive impact through education, career guidance, health initiatives, and humanitarian efforts. Join us in shaping a brighter future for the underprivileged, empowering individuals to succeed in their careers, fostering community well-being, and providing care to those in need. Together, let's work towards a more compassionate and equitable world.</li>
      <li><span className='font-semibold'>Day 2 - Creating Impact: </span>On the second day of our conclave, we delve into critical areas that drive positive change. We highlight the significance of women's empowerment and inclusive hiring practices for societal advancement. Environmental sustainability takes center stage as we explore collective responsibilities and strategies for a greener future through ESG practices. Our commitment to rural communities leads us to discuss effective strategies for uplifting villages and fostering holistic development. Additionally, we shed light on disaster response measures and collaborative efforts to mitigate the impact of emergencies. Together, let's create a lasting impact and contribute to a more sustainable and resilient world.</li>
      </ul>

      {/* Agenda */}
      <div className='mt-28 pb-10 bg-gradient-to-b from-pink-50 to-blue-50' id="Agenda">
        <div className='flex font-bold py-16 md:text-5xl text-4xl justify-center  '>Agenda</div>
        {/* <div className='md:mx-20 ml-10 mr-8'> */}

        <div className='md:mx-20 ml-10 mr-8'>
        <div className='md:text-3xl text-3xl pb-2'>Jul 27,2023</div>
        <div className='bg-white h-[0.1rem]'></div>
        <div className='font-semibold my-10 text-2xl'>Main Schedule</div>
        <ol className="relative border-l-2 border-red-400 dark:border-red-700">
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>9:45 AM to 11:30 AM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Education 🎓</div>
            <div className='max-w-sm mt-1 text-xl'> Empowering underserved schools with coding/digital labs, STEM learning, sports, and language programs, fostering well-rounded education for a brighter future. </div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>12:00 PM to 1:30 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'> Career Guidance and Career Counseling 🚀 </div>
            <div className='max-w-sm mt-1 text-xl'> Providing comprehensive support through career guidance, scholarships, and mentoring, empowering individuals to achieve their full potential and pursue a successful future. </div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>2:30 PM to 4:00 PM  </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Health & Mental Health 🧠 </div>
            <div className='max-w-sm mt-1 text-xl'> Prioritizing both physical and mental health, offering holistic primary healthcare solutions and mental health support to promote overall well-being and resilience in individuals.</div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>4:30 PM to 6:00 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Food for Good and Sports 🍲</div>
            <div className='max-w-sm mt-1 text-xl'> Working towards achieving zero hunger and ensuring food for all, while also providing shelter homes, orphanages, and old-age homes, fostering a caring and supportive community for vulnerable populations.</div>
          </li>
        </ol>
        <div className='md:text-3xl text-3xl pb-2'>Jul 28,2023</div>
        <div className='bg-white h-[0.1rem]'></div>
        <div className='font-semibold my-10 text-2xl'>Main Schedule</div>
        <ol className="relative border-l-2 border-red-400 dark:border-red-700">
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>9:30 AM to 11:30 AM</div>
            <div className='font-bold text-2xl ml-auto mt-1'>Women Empowerment 🙍‍♀️</div>
            <div className='max-w-sm mt-1 text-xl'> Empowering women through skill development, self-employment, and entrepreneurship, promoting impact hiring for sustainable economic growth and gender equality.</div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>12:00 PM to 1:30 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Diversity and Inclusion 🌈 </div>
            <div className='max-w-sm mt-1 text-xl'> Embracing Diversity and Inclusion - Empowering Persons with Disabilities and LGBTQ+ Communities, fostering an equitable and inclusive society for all.</div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>2:30 PM to 4:00 PM  </div>
            <div className='font-bold text-2xl ml-auto mt-1'> Environment 🌿</div>
            <div className='max-w-sm mt-1 text-xl'> Preserving Our Planet - Promoting Environmental Conservation and Sustainable Practices for a Greener Future.</div>
          </li>
          <li className='mb-10  ml-10'>
            <div className="absolute w-4 h-4 bg-pink-300 rounded-full mt-1.5 -left-[0.6rem] border-2 border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <div className='font-semibold text-xl'>4:30 PM to 6:00 PM </div>
            <div className='font-bold text-2xl ml-auto mt-1'>Rural Development and Empowerment 🏞️</div>
            <div className='max-w-sm mt-1 text-xl'> Strengthening Disaster Response, Community Engagement, and Transforming Rural Communities.</div>
          </li>
        </ol>
        </div>
      </div>

      {/* Speakers */}
      <div className='mb-20'>
        <div className='flex justify-center font-bold md:text-5xl text-4xl mt-20 mb-10  ' id="Speakers">Speakers</div>
        <div className='grid md:grid-cols-3 grid-cols-1 gap-10 px-16'>
        <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-cover mx-auto' src="/speakers/madan.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Madan Pillutla</div>
            </div>
            <div className='text-md text-center'>Dean & Professor, Indian School of Business.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 md:p-0 p-4 object-contain mx-auto' src="/speakers/archana.png"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Archana Suresh</div>
            </div>
            <div className='text-md text-center'>Director, Telangana Social Impact Group (T-SIG).</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/jitendra.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Jitendra Chakravarthy Putcha</div>
            </div>
            <div className='text-md text-center'>EVP & Global Head, LTI Mindtree</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/neeraja.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Neeraja Vijender</div>
            </div>
            <div className='text-md text-center'>Director & Head of Operations, HYSEA.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/Divya.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Divya Devarajan IAS</div>
            </div>
            <div className='text-md text-center'>Project Officer, Integrated Tribal Development Agency at Government of Telangana.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/jebakumar.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Jebakumar Swvigaradoss</div>
            </div>
            <div className='text-md text-center'>Senior Director at Servicenow.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/prashanth.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Prashanth Nandella</div>
            </div>
            <div className='text-md text-center'>COO at Firstsource.</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/nithin.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Nitin Sharma</div>
            </div>
            <div className='text-md text-center'>Technology Counsel (Sr. Director, Technology/Patent Counsel).</div>
          </div>
          <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/speakers/chandrashekar.jpg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Shri. G. Chandrashekar IFoS</div>
            </div>
            <div className='text-md text-center'>Sr. Director, Engineering at Qualcomm.</div>
          </div>
          {/* <div>
            <div className='md:p-5 md:pb-0'>
              <img className='rounded-full h-40 w-40 object-cover md:p-0 p-4 mx-auto' src="/png/jathin.jpeg"></img>
              <div className='text-center text-xl font-semibold text-red-500'>Nitin Sharma</div>
            </div>
            <div className='text-md text-center'>Sr. Director, Engineering at Qualcomm.</div>
          </div> */}
        </div>
      </div>

      {/* Partners */}
      <div className='font-custom pb-20 bg-gradient-to-b from-pink-50 to-blue-50'id='Partners'>
        <div className='font-custom text-5xl text-center pt-10 font-bold'>Partners</div>
        <div className='font-custom grid sm:grid-cols-3 px-16 gap-x-6 gap-y-16 grid-cols-1  mt-24'>
        <div className='font-custom flex justify-center items-center text-3xl ' >ADAMA</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >ADP</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>Advance</div> <span className='font-custom flex justify-center items-center text-3xl'>Auto Parts</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >AVEVA</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Bosch</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Carrier</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >CHUBB</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Cognizant</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Colruyt</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >DBS TECH</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>D E Shaw</div> <span className='font-custom flex justify-center items-center text-3xl'>& Co</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >epam</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >experian</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >F5 Networks</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >firstsource</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >HCLFoundation</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >HSBC</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Infosys</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Inorbit</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>Indian Oil</div> <span className='font-custom flex justify-center items-center text-3xl'>Corporation</span> <span className='font-custom flex justify-center items-center text-3xl'> Limited</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >InteractiveBrokers</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Invesco</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Micron</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Microsoft</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >NOVARTIS</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >NTT Data</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >opentext</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Pegasystems</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >PEPSICO</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Qualcomm</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >Rotary</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >savills</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >SenecaGlobal</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >servicenow</div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>SILICON</div> <span className='font-custom flex justify-center items-center text-3xl'>LABS</span></div>
        <div><div className='font-custom flex justify-center items-center text-3xl'>STATE</div> <span className='font-custom flex justify-center items-center text-3xl'>STREET</span></div>
        <div className='font-custom flex justify-center items-center text-3xl ' >synchrony</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >synopsys</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >STELLANTIS</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >UST</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >valueMomentum</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >verizon</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >virtusa</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >vmware</div>
        <div className='font-custom flex justify-center items-center text-3xl ' >WELLSFARGO</div>
      </div>
      </div>

      {/* Venue */}
      <div id="Venue">
      <div className='md:text-5xl text-4xl text-center py-10 font-bold  '>Venue</div>
      <div className='grid md:grid-cols-5'>
      <div className='col-span-2'>
        <div>
          <img className='w-full' src="/png/ven.jpg"></img>
          <div className='px-5'>
          <div className='text-xl font-bold my-2 '>Venue</div>
          <div className=' font-bold'>Swagath-De-Royal</div>
          <div>Kothaguda Cross Road Cyberabad, Gachibowli - Miyapur Rd, Hyderabad, Telangana 500081</div>
          </div>
          <div className='flex justify-center'>
           <a className='bg-red-500 p-2 w-fit text-white my-6 font-bold rounded-full' target='_blank'  href='https://www.google.com/maps/dir//Swagath-De-Royal+Kothaguda+Cross+Road+Cyberabad+Gachibowli+-+Miyapur+Rd+Hyderabad,+Telangana+500081/@17.460088,78.3660471,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bcb93c93eb2544d:0xac79d3f07c3d06fb!2m2!1d78.3661049!2d17.4600717?entry=ttu'> Get Directions</a> 
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className='col-span-3'>
      <iframe className='md:rounded-br-[2rem] md:rounded-bl-none rounded-b-[2rem] h-[300px] md:h-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.9920815002374!2d78.36347217385034!3d17.460093100709106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93c93eb2544d%3A0xac79d3f07c3d06fb!2sSwagath-De-Royal!5e0!3m2!1sen!2sin!4v1690264358387!5m2!1sen!2sin" style={{border:"0", width:"100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      </div>
      </div>
    </div>
    </div>
    </div>
    <a href='#top' className='fixed bottom-4 right-4'><img className='md:h-10 h-8' src="/png/up-arrow.png"></img></a>
  </div>
  );
}

export default App;
